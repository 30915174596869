html {
	width: 100%;
	height: 100%;
	user-select: none;
	/*
	overflow-y: auto;
	overflow-x: hidden;
	*/
}

body {
	margin: 0;
	font-family: Ubuntu, sans-serif;
	font-size: 2vh;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
}

#root {
	width: 100%;
	height: 100%;
	/*
	position: relative;
	min-height: 600px;
	*/
	overflow: hidden;
}
