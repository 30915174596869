.bounce {
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-name: bounce;
	transform-origin: bottom;
	animation-timing-function: ease;
}
@keyframes bounce {
	0% {
		transform: scale(1, 1) translateY(0);
	}
	10% {
		transform: scale(1.02, 0.98) translateY(0);
	}
	30% {
		transform: scale(0.98, 1.02) translateY(-20px);
	}
	50% {
		transform: scale(1.01, 0.99) translateY(0);
	}
	57% {
		transform: scale(1, 1) translateY(-1px);
	}
	64% {
		transform: scale(1, 1) translateY(0);
	}
	100% {
		transform: scale(1, 1) translateY(0);
	}
}
