.pulse {
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-name: pulse;
	animation-timing-function: ease-in-out;
}
@keyframes pulse {
	from {
		transform: scale3d(1, 1, 1);
	}

	50% {
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		transform: scale3d(1, 1, 1);
	}
}
