.avatar {
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-iteration-count: 1;
	animation-name: slideInUp;
}
@keyframes slideInUp {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: hidden;
	}

	to {
		transform: translate3d(0, 0, 0);
		visibility: visible;
	}
}
